import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import MainRoutes from "./main-routes";
import FormRoutes from "./form-routes";
import PublicRoutes from "./public-routes";
import CustomerRoutes from "./customer-routes";
// import store from "../store/index";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  ...MainRoutes,
  ...FormRoutes,
  ...PublicRoutes,
  ...CustomerRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    next(); // Temporary
    /*
    if (!store.getters.isLoggedIn) {
      next({ name: "login" });
    } else {
      next(); // go to wherever I'm going
    }
    */
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

export default router;
