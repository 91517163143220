const MainMaster = () => import("/src/masters/MainMaster.vue");

const Main = () => import("/src/views/Main.vue");

const AdminDashboard = () => import("/src/views/dashboard/AdminDashboard.vue");
const AccountDashboard = () =>
  import("/src/views/dashboard/AccountDashboard.vue");
const SalesRepDashboard = () =>
  import("/src/views/dashboard/SalesRepDashboard.vue");
const SalesAgentDashboard = () =>
  import("/src/views/dashboard/SalesAgentDashboard.vue");
const FulfilmentAgentDashboard = () =>
  import("/src/views/dashboard/FulfilmentAgentDashboard.vue");
const ProfileView = () => import("/src/views/profiles/ProfileView.vue");
const Logout = () => import("/src/views/Logout.vue");
const ReportList = () => import("/src/views/reports/ReportList.vue");

const LeadList = () => import("/src/views/leads/LeadList.vue");
const LeadView = () => import("/src/views/leads/LeadView.vue");
const LeadPrint = () => import("/src/views/leads/LeadPrint.vue");
const BatchLeadList = () => import("/src/views/batchLeads/BatchLeadList.vue");
const BatchLeadView = () => import("/src/views/batchLeads/BatchLeadView.vue");
const LeadItemView = () => import("/src/views/batchLeads/LeadItemView.vue");
const CustomerList = () => import("/src/views/customers/CustomerList.vue");
const CustomerView = () => import("/src/views/customers/CustomerView.vue");
const CustomerPrint = () => import("/src/views/customers/CustomerPrint.vue");
const OrderList = () => import("/src/views/orders/OrderList.vue");
const OrderView = () => import("/src/views/orders/OrderView.vue");
const OrderPrint = () => import("/src/views/orders/OrderPrint.vue");
const ProductList = () => import("/src/views/products/ProductList.vue");
const ProductView = () => import("/src/views/products/ProductView.vue");
const ProgramList = () => import("/src/views/programs/ProgramList.vue");
const ProgramView = () => import("/src/views/programs/ProgramView.vue");
const SalesRepList = () => import("/src/views/salesReps/SalesRepList.vue");
const SalesRepView = () => import("/src/views/salesReps/SalesRepView.vue");
const SalesGroupList = () =>
  import("/src/views/salesGroups/SalesGroupList.vue");
const SalesGroupView = () =>
  import("/src/views/salesGroups/SalesGroupView.vue");
const FulfilmentBatchList = () =>
  import("/src/views/fulfilmentBatches/FulfilmentBatchList.vue");
const FulfilmentBatchView = () =>
  import("/src/views/fulfilmentBatches/FulfilmentBatchView.vue");
const FulfilmentAgentList = () =>
  import("/src/views/fulfilmentAgents/FulfilmentAgentList.vue");
const FulfilmentAgentView = () =>
  import("/src/views/fulfilmentAgents/FulfilmentAgentView.vue");
const FulfilmentBatchList01 = () =>
  import("/src/views/fulfilmentBatches01/FulfilmentBatchList.vue");
const FulfilmentBatchView01 = () =>
  import("/src/views/fulfilmentBatches01/FulfilmentBatchView.vue");
const OrganizationView = () =>
  import("/src/views/organization/OrganizationView.vue");
const ReceivePaymentView = () =>
  import("/src/views/receivePayments/ReceivePaymentView.vue");

export default [
  {
    path: "/internal",
    name: "MainMaster",
    component: MainMaster,
    children: [
      {
        path: "/main",
        name: "main",
        meta: {
          requiresAuth: true,
        },
        component: Main,
      },
      {
        path: "/adminDashboard",
        name: "adminDashboard",
        meta: {
          requiresAuth: true,
        },
        component: AdminDashboard,
      },
      {
        path: "/accountDashboard",
        name: "accountDashboard",
        meta: {
          requiresAuth: true,
        },
        component: AccountDashboard,
      },
      {
        path: "/salesRepDashboard",
        name: "salesRepDashboard",
        meta: {
          requiresAuth: true,
        },
        component: SalesRepDashboard,
      },
      {
        path: "/salesAgentDashboard",
        name: "salesAgentDashboard",
        meta: {
          requiresAuth: true,
        },
        component: SalesAgentDashboard,
      },
      {
        path: "/fulfilmentAgentDashboard",
        name: "fulfilmentAgentDashboard",
        meta: {
          requiresAuth: true,
        },
        component: FulfilmentAgentDashboard,
      },
      {
        path: "/profileView",
        name: "profileView",
        meta: {
          requiresAuth: true,
        },
        component: ProfileView,
      },
      {
        path: "/logout",
        name: "logout",
        component: Logout,
      },
      {
        path: "/reportList",
        name: "reportList",
        component: ReportList,
      },
      {
        path: "/leadList",
        name: "leadList",
        component: LeadList,
      },
      {
        path: "/leadView/:id",
        name: "leadView",
        component: LeadView,
      },
      {
        path: "/leadPrint",
        name: "leadPrint",
        component: LeadPrint,
      },
      {
        path: "/batchLeadList",
        name: "batchLeadList",
        component: BatchLeadList,
      },
      {
        path: "/batchLeadView/:id",
        name: "batchLeadView",
        component: BatchLeadView,
      },
      {
        path: "/leadItemView/:bid/:id",
        name: "leadItemView",
        component: LeadItemView,
      },
      {
        path: "/customerList",
        name: "customerList",
        component: CustomerList,
      },
      {
        path: "/customerView/:id",
        name: "customerView",
        component: CustomerView,
      },
      {
        path: "/customerPrint",
        name: "customerPrint",
        component: CustomerPrint,
      },
      {
        path: "/orderList",
        name: "orderList",
        component: OrderList,
      },
      {
        path: "/orderView/:id",
        name: "orderView",
        component: OrderView,
      },
      {
        path: "/orderPrint",
        name: "orderPrint",
        component: OrderPrint,
      },
      {
        path: "/productList",
        name: "productList",
        component: ProductList,
      },
      {
        path: "/productView/:id",
        name: "productView",
        component: ProductView,
      },
      {
        path: "/programList",
        name: "programList",
        component: ProgramList,
      },
      {
        path: "/programView/:id",
        name: "programView",
        component: ProgramView,
      },
      {
        path: "/salesRepList",
        name: "salesRepList",
        component: SalesRepList,
      },
      {
        path: "/salesRepView/:id",
        name: "salesRepView",
        component: SalesRepView,
      },
      {
        path: "/salesGroupList",
        name: "salesGroupList",
        component: SalesGroupList,
      },
      {
        path: "/salesGroupView/:id",
        name: "salesGroupView",
        component: SalesGroupView,
      },
      {
        path: "/fulfilmentBatchList",
        name: "fulfilmentBatchList",
        component: FulfilmentBatchList,
      },
      {
        path: "/fulfilmentBatchView/:id",
        name: "fulfilmentBatchView",
        component: FulfilmentBatchView,
      },
      {
        path: "/fulfilmentAgentList",
        name: "fulfilmentAgentList",
        component: FulfilmentAgentList,
      },
      {
        path: "/fulfilmentAgentView/:id",
        name: "fulfilmentAgentView",
        component: FulfilmentAgentView,
      },
      {
        path: "/fulfilmentBatchList01",
        name: "fulfilmentBatchList01",
        component: FulfilmentBatchList01,
      },
      {
        path: "/fulfilmentBatchView01/:id",
        name: "fulfilmentBatchView01",
        component: FulfilmentBatchView01,
      },
      {
        path: "/organizationView",
        name: "organizationView",
        component: OrganizationView,
      },
      {
        path: "/receivePaymentView/:id/:oid",
        name: "receivePaymentView",
        component: ReceivePaymentView,
      },
    ],
  },
];
