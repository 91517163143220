import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  state: {
    host: "https://api-revops.netai.my:4443",
    // host: "http://167.99.30.32:4003",
    // host: "http://localhost:4003",
    collapsed: true,
    isOnMobile: false,
    userId: null,
    userName: null,
    externalFlag: null,
    orgId: null,
    token: null,
    roles: new Array<string>(),
    currentModule: null,
    mainPage: null,
    isPageLoading: false,
    salesRepId: null,
    leadSearch: null,
    orderSearch: null,
    orderSearchKeyword: null,
    orderSearchPeriodCode: null,
    orderSearchTypeCode: null,
    orderSearchProgramCode: null,
    orderSearchProductCode: null,
    orderSearchSalesGroupCode: null,
    orderSearchSalesRepCode: null,
    orderSearchPaymentModeCode: null,
    orderSearchStatusCode: null,
    orderSearchStartDate: null,
    orderSearchEndDate: null,
    batchSearchKeyword: null,
    batchSearchPeriodCode: null,
    batchSearchTypeCode: null,
    batchSearchProgramCode: null,
    batchSearchAgentCode: null,
    batchSearchStatusCode: null,
    batchSearchStartDate: null,
    batchSearchEndDate: null,
    customerSearch: null,
    customerId: null,
    customerName: null,
    customerOrgId: null,
    vendorSearchKeyword: null,
    orderCurrentPage: null,
    batchCurrentPage: null,
    leadCurrentPage: null,
    vendorBatchCurrentPage: null,
  },
  getters: {
    isLoggedIn(state) {
      return state.userId !== null;
    },
    isExternal(state) {
      return state.externalFlag === 2;
    },
    isSysAdmin(state) {
      return state.roles.includes("01");
    },
    isAdmin(state) {
      return state.roles.includes("02");
    },
    isSalesRep(state) {
      return state.roles.includes("03");
    },
    isAccount(state) {
      return state.roles.includes("04");
    },
    isFulfilmentAgent(state) {
      return state.roles.includes("05");
    },
    isSalesAgent(state) {
      return state.roles.includes("06");
    },
  },
  mutations: {
    toggleSidebarState(state) {
      state.collapsed = !state.collapsed;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    setUserName(state, userName) {
      state.userName = userName;
    },
    setRoles(state, roles) {
      state.roles = roles;
    },
    setHost(state, host) {
      state.host = host;
    },
    setExternalFlag(state, externalFlag) {
      state.externalFlag = externalFlag;
    },
    setOrgId(state, orgId) {
      state.orgId = orgId;
    },
    setToken(state, token) {
      state.token = token;
    },
    setSalesRepId(state, salesRepId) {
      state.salesRepId = salesRepId;
    },
    setLeadSearch(state, leadSearch) {
      state.leadSearch = leadSearch;
    },
    setOrderSearch(state, orderSearch) {
      state.orderSearch = orderSearch;
    },
    setOrderSearchKeyword(state, keyword) {
      state.orderSearchKeyword = keyword;
    },
    setOrderSearchPeriodCode(state, code) {
      state.orderSearchPeriodCode = code;
    },
    setOrderSearchTypeCode(state, code) {
      state.orderSearchTypeCode = code;
    },
    setOrderSearchProgramCode(state, code) {
      state.orderSearchProgramCode = code;
    },
    setOrderSearchProductCode(state, code) {
      state.orderSearchProductCode = code;
    },
    setOrderSearchSalesGroupCode(state, code) {
      state.orderSearchSalesGroupCode = code;
    },
    setOrderSearchSalesRepCode(state, code) {
      state.orderSearchSalesRepCode = code;
    },
    setOrderSearchPaymentModeCode(state, code) {
      state.orderSearchPaymentModeCode = code;
    },
    setOrderSearchStatusCode(state, code) {
      state.orderSearchStatusCode = code;
    },
    setOrderSearchStartDate(state, date) {
      state.orderSearchStartDate = date;
    },
    setOrderSearchEndDate(state, date) {
      state.orderSearchEndDate = date;
    },
    setBatchSearchKeyword(state, keyword) {
      state.batchSearchKeyword = keyword;
    },
    setBatchSearchPeriodCode(state, code) {
      state.batchSearchPeriodCode = code;
    },
    setBatchSearchTypeCode(state, code) {
      state.batchSearchTypeCode = code;
    },
    setBatchSearchProgramCode(state, code) {
      state.batchSearchProgramCode = code;
    },
    setBatchSearchAgentCode(state, code) {
      state.batchSearchAgentCode = code;
    },
    setBatchSearchStatusCode(state, code) {
      state.batchSearchStatusCode = code;
    },
    setBatchSearchStartDate(state, date) {
      state.batchSearchStartDate = date;
    },
    setBatchSearchEndDate(state, date) {
      state.batchSearchEndDate = date;
    },
    setCustomerSearch(state, customerSearch) {
      state.customerSearch = customerSearch;
    },
    setVendorSearchKeyword(state, vendorSearchKeyword) {
      state.vendorSearchKeyword = vendorSearchKeyword;
    },
    setCurrentModule(state, code) {
      state.currentModule = code;
    },
    setMainPage(state, mainPage) {
      state.mainPage = mainPage;
    },
    setCustomerId(state, cid) {
      state.customerId = cid;
    },
    setCustomerName(state, name) {
      state.customerName = name;
    },
    setCustomerOrgId(state, oid) {
      state.customerOrgId = oid;
    },
    setOrderCurrentPage(state, page) {
      state.orderCurrentPage = page;
    },
    setBatchCurrentPage(state, page) {
      state.batchCurrentPage = page;
    },
    setLeadCurrentPage(state, page) {
      state.leadCurrentPage = page;
    },
    setVendorBatchCurrentPage(state, page) {
      state.vendorBatchCurrentPage = page;
    },
    logout(state) {
      state.userId = null;
      state.userName = null;
      state.roles = [];
      state.orgId = null;
      state.token = null;
      state.mainPage = null;
      state.salesRepId = null;
      state.orderSearch = null;
      state.orderSearchKeyword = null;
      state.orderSearchTypeCode = null;
      state.orderSearchProgramCode = null;
      state.orderSearchProductCode = null;
      state.orderSearchSalesGroupCode = null;
      state.orderSearchSalesRepCode = null;
      state.orderSearchPaymentModeCode = null;
      state.orderSearchPeriodCode = null;
      state.orderSearchStatusCode = null;
      state.orderSearchStartDate = null;
      state.orderSearchEndDate = null;
      state.batchSearchKeyword = null;
      state.batchSearchTypeCode = null;
      state.batchSearchProgramCode = null;
      state.batchSearchAgentCode = null;
      state.batchSearchPeriodCode = null;
      state.batchSearchStatusCode = null;
      state.batchSearchStartDate = null;
      state.batchSearchEndDate = null;
      state.vendorSearchKeyword = null;
      state.currentModule = null;
      state.customerSearch = null;
      state.orderCurrentPage = null;
      state.batchCurrentPage = null;
      state.leadCurrentPage = null;
      state.vendorBatchCurrentPage = null;
      state.currentModule = null;
      state.leadSearch = null;
      state.externalFlag = null;
    },
    customerLogout(state) {
      state.customerId = null;
      state.customerName = null;
      state.customerOrgId = null;
    },
  },
  actions: {},
  modules: {},
  // plugins: [new VuexPersistence().plugin],
  plugins: [vuexLocal.plugin],
});
