const CustomerPublicMaster = () =>
  import("/src/masters/CustomerPublicMaster.vue");
const CustomerMainMaster = () => import("/src/masters/CustomerMainMaster.vue");

const CustomerAccess = () =>
  import("/src/views/customerDashboard/CustomerAccess.vue");
const CustomerDashboard = () =>
  import("/src/views/customerDashboard/CustomerDashboard.vue");
const CustomerOrderList = () =>
  import("/src/views/customerDashboard/CustomerOrderList.vue");
const CustomerOrderDetails = () =>
  import("/src/views/customerDashboard/CustomerOrderDetails.vue");
const CustomerLogout = () => import("/src/views/CustomerLogout.vue");

export default [
  {
    path: "/",
    name: "CustomerPublicMaster",
    component: CustomerPublicMaster,
    children: [
      {
        path: "/customerAccess",
        name: "customerAccess",
        component: CustomerAccess,
      },
      {
        path: "/customerAccess2/:code",
        name: "customerAccess2",
        component: CustomerAccess,
      },
    ],
  },
  {
    path: "/",
    name: "CustomerMainMaster",
    component: CustomerMainMaster,
    children: [
      {
        path: "/customerDashboard/:cid",
        name: "customerDashboard",
        component: CustomerDashboard,
      },
      {
        path: "/customerOrderList/:cid",
        name: "customerOrderList",
        component: CustomerOrderList,
      },
      {
        path: "/customerOrderDetails/:cid/:id",
        name: "customerOrderDetails",
        component: CustomerOrderDetails,
      },
      {
        path: "/customerLogout",
        name: "customerLogout",
        component: CustomerLogout,
      },
    ],
  },
];
