<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss"></style>

<script>
export default {
  name: "app",
  components: {},
  mounted() {
    // this.$i18n.locale = localStorage.getItem("revops-locale") || "ms";
    // this.$store.commit("setHost", "http://localhost:4003");
    // this.$store.commit("setHost", "http://82.197.92.44:4003");
    // this.$store.commit("setHost", "http://139.59.116.246:4003");
    // this.$store.commit("setHost", "http://167.99.30.32:4003");
    this.$store.commit("setHost", "https://api-revops.netai.my:4443");
  },
};
</script>
