const FormMaster = () => import("/src/masters/FormMaster.vue");

// Lead
const LeadAdd = () => import("/src/views/leads/LeadAdd.vue");
const LeadEdit = () => import("/src/views/leads/LeadEdit.vue");
const LeadDelete = () => import("/src/views/leads/LeadDelete.vue");
// Batch Lead
const BatchLeadEdit = () => import("/src/views/batchLeads/BatchLeadEdit.vue");
const BatchLeadDelete = () =>
  import("/src/views/batchLeads/BatchLeadDelete.vue");
// Lead Item Edit
const LeadItemEdit = () => import("/src/views/batchLeads/LeadItemEdit.vue");
// Customer
const CustomerAdd = () => import("/src/views/customers/CustomerAdd.vue");
const CustomerEdit = () => import("/src/views/customers/CustomerEdit.vue");
const CustomerDelete = () => import("/src/views/customers/CustomerDelete.vue");
// Order
const OrderAdd = () => import("/src/views/orders/OrderAdd.vue");
const OrderEdit = () => import("/src/views/orders/OrderEdit.vue");
const OrderDelete = () => import("/src/views/orders/OrderDelete.vue");
// Fulfilment Batch
const FulfilmentBatchAdd = () =>
  import("/src/views/fulfilmentBatches/FulfilmentBatchAdd.vue");
const FulfilmentBatchEdit = () =>
  import("/src/views/fulfilmentBatches/FulfilmentBatchEdit.vue");
const FulfilmentBatchDelete = () =>
  import("/src/views/fulfilmentBatches/FulfilmentBatchDelete.vue");
const FulfilmentBatchGenerate = () =>
  import("/src/views/fulfilmentBatches/FulfilmentBatchGenerate.vue");
const FulfilmentBatchAssign = () =>
  import("/src/views/fulfilmentBatches/FulfilmentBatchAssign.vue");
// Fulfilment Batch 01
const FulfilmentBatchEdit01 = () =>
  import("/src/views/fulfilmentBatches01/FulfilmentBatchEdit.vue");
// Fulfilment Agent
const FulfilmentAgentAdd = () =>
  import("/src/views/fulfilmentAgents/FulfilmentAgentAdd.vue");
const FulfilmentAgentEdit = () =>
  import("/src/views/fulfilmentAgents/FulfilmentAgentEdit.vue");
const FulfilmentAgentDelete = () =>
  import("/src/views/fulfilmentAgents/FulfilmentAgentDelete.vue");
// Product
const ProductAdd = () => import("/src/views/products/ProductAdd.vue");
const ProductEdit = () => import("/src/views/products/ProductEdit.vue");
const ProductDelete = () => import("/src/views/products/ProductDelete.vue");
// Program
const ProgramAdd = () => import("/src/views/programs/ProgramAdd.vue");
const ProgramEdit = () => import("/src/views/programs/ProgramEdit.vue");
const ProgramDelete = () => import("/src/views/programs/ProgramDelete.vue");
// Sales Rep
const SalesRepAdd = () => import("/src/views/salesReps/SalesRepAdd.vue");
const SalesRepEdit = () => import("/src/views/salesReps/SalesRepEdit.vue");
const SalesRepDelete = () => import("/src/views/salesReps/SalesRepDelete.vue");
// Sales Group
const SalesGroupAdd = () => import("/src/views/salesGroups/SalesGroupAdd.vue");
const SalesGroupEdit = () =>
  import("/src/views/salesGroups/SalesGroupEdit.vue");
const SalesGroupDelete = () =>
  import("/src/views/salesGroups/SalesGroupDelete.vue");
// Receive Payment
const ReceivePaymentAdd = () =>
  import("/src/views/receivePayments/ReceivePaymentAdd.vue");
const ReceivePaymentEdit = () =>
  import("/src/views/receivePayments/ReceivePaymentEdit.vue");
const ReceivePaymentCancel = () =>
  import("/src/views/receivePayments/ReceivePaymentCancel.vue");
const ReceivePaymentDelete = () =>
  import("/src/views/receivePayments/ReceivePaymentDelete.vue");

export default [
  {
    path: "/form",
    name: "FormMaster",
    component: FormMaster,
    children: [
      {
        path: "/leadAdd",
        name: "leadAdd",
        component: LeadAdd,
      },
      {
        path: "/leadEdit/:id",
        name: "leadEdit",
        component: LeadEdit,
      },
      {
        path: "/leadDelete/:id",
        name: "leadDelete",
        component: LeadDelete,
      },
      {
        path: "/batchLeadEdit/:id",
        name: "batchLeadEdit",
        component: BatchLeadEdit,
      },
      {
        path: "/batchLeadDelete/:id",
        name: "batchLeadDelete",
        component: BatchLeadDelete,
      },
      {
        path: "/leadItemEdit/:bid/:id",
        name: "leadItemEdit",
        component: LeadItemEdit,
      },
      {
        path: "/customerAdd",
        name: "customerAdd",
        component: CustomerAdd,
      },
      {
        path: "/customerEdit/:id",
        name: "customerEdit",
        component: CustomerEdit,
      },
      {
        path: "/customerDelete/:id",
        name: "customerDelete",
        component: CustomerDelete,
      },
      {
        path: "/orderAdd",
        name: "orderAdd",
        component: OrderAdd,
      },
      {
        path: "/orderEdit/:id",
        name: "orderEdit",
        component: OrderEdit,
      },
      {
        path: "/orderDelete/:id",
        name: "orderDelete",
        component: OrderDelete,
      },
      {
        path: "/fulfilmentBatchAdd",
        name: "fulfilmentBatchAdd",
        component: FulfilmentBatchAdd,
      },
      {
        path: "/fulfilmentBatchEdit/:id",
        name: "fulfilmentBatchEdit",
        component: FulfilmentBatchEdit,
      },
      {
        path: "/fulfilmentBatchDelete/:id",
        name: "fulfilmentBatchDelete",
        component: FulfilmentBatchDelete,
      },
      {
        path: "/fulfilmentBatchEdit01/:id",
        name: "fulfilmentBatchEdit01",
        component: FulfilmentBatchEdit01,
      },
      {
        path: "/fulfilmentBatchGenerate",
        name: "fulfilmentBatchGenerate",
        component: FulfilmentBatchGenerate,
      },
      {
        path: "/fulfilmentBatchAssign",
        name: "fulfilmentBatchAssign",
        component: FulfilmentBatchAssign,
      },
      {
        path: "/fulfilmentAgentAdd",
        name: "fulfilmentAgentAdd",
        component: FulfilmentAgentAdd,
      },
      {
        path: "/fulfilmentAgentEdit/:id",
        name: "fulfilmentAgentEdit",
        component: FulfilmentAgentEdit,
      },
      {
        path: "/fulfilmentAgentDelete/:id",
        name: "fulfilmentAgentDelete",
        component: FulfilmentAgentDelete,
      },
      {
        path: "/productAdd",
        name: "productAdd",
        component: ProductAdd,
      },
      {
        path: "/productEdit/:id",
        name: "productEdit",
        component: ProductEdit,
      },
      {
        path: "/productDelete/:id",
        name: "productDelete",
        component: ProductDelete,
      },
      {
        path: "/programAdd",
        name: "programAdd",
        component: ProgramAdd,
      },
      {
        path: "/programEdit/:id",
        name: "programEdit",
        component: ProgramEdit,
      },
      {
        path: "/programDelete/:id",
        name: "programDelete",
        component: ProgramDelete,
      },
      {
        path: "/salesRepAdd",
        name: "salesRepAdd",
        component: SalesRepAdd,
      },
      {
        path: "/salesRepEdit/:id",
        name: "salesRepEdit",
        component: SalesRepEdit,
      },
      {
        path: "/salesRepDelete/:id",
        name: "salesRepDelete",
        component: SalesRepDelete,
      },
      {
        path: "/salesGroupAdd",
        name: "salesGroupAdd",
        component: SalesGroupAdd,
      },
      {
        path: "/salesGroupEdit/:id",
        name: "salesGroupEdit",
        component: SalesGroupEdit,
      },
      {
        path: "/salesGroupDelete/:id",
        name: "salesGroupDelete",
        component: SalesGroupDelete,
      },
      {
        path: "/receivePaymentAdd/:id",
        name: "receivePaymentAdd",
        component: ReceivePaymentAdd,
      },
      {
        path: "/receivePaymentEdit/:id/:oid",
        name: "receivePaymentEdit",
        component: ReceivePaymentEdit,
      },
      {
        path: "/receivePaymentCancel/:id",
        name: "receivePaymentCancel",
        component: ReceivePaymentCancel,
      },
      {
        path: "/receivePaymentDelete/:id/:oid",
        name: "receivePaymentDelete",
        component: ReceivePaymentDelete,
      },
    ],
  },
];
