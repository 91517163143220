<template>
  <b-container fluid class="home px-0">
    <b-jumbotron
      fluid
      text-variant="white"
      header-level="5"
      v-bind:header="$t('jumbo-header')"
      v-bind:lead="$t('jumbo-lead')"
      class="jumbotron-1"
      style="height: 300px"
    />
    <b-jumbotron
      fluid
      bg-variant="white"
      text-variant="primary"
      style="padding: 0px"
    >
      <b-row>
        <b-col>
          <b-card :title="$t('subTitle1')" class="mt-2" style="height: 250px">
            <b-card-text>
              {{ $t("subTitle1Text") }}
            </b-card-text>
          </b-card>
        </b-col>
        <b-col>
          <b-card :title="$t('subTitle2')" class="mt-2" style="height: 250px">
            <b-card-text>
              {{ $t("subTitle2Text") }}
            </b-card-text>
          </b-card>
        </b-col>
        <b-col>
          <b-card :title="$t('subTitle3')" class="mt-2" style="height: 250px">
            <b-card-text>
              {{ $t("subTitle3Text") }}
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-jumbotron>
    <b-jumbotron
      fluid
      bg-variant="dark"
      text-variant="white"
      class="mb-0 mt-0 pt-5"
      style="height: 300px"
    >
      <b-row>
        <b-col>
          <b-nav vertical>
            <b-nav-item>
              <h6 class="text-white">{{ $t("footerTitle1") }}</h6>
            </b-nav-item>
            <b-nav-item>
              <p class="text-white mb-0">{{ $t("footerFeature1") }}</p>
            </b-nav-item>
            <b-nav-item>
              <p class="text-white mb-0">{{ $t("footerFeature2") }}</p>
            </b-nav-item>
            <b-nav-item>
              <p class="text-white mb-0">{{ $t("footerFeature3") }}</p>
            </b-nav-item>
            <b-nav-item>
              <p class="text-white mb-0">{{ $t("footerFeature4") }}</p>
            </b-nav-item>
          </b-nav>
        </b-col>
        <b-col>
          <b-nav vertical>
            <b-nav-item>
              <h6 class="text-white">{{ $t("footerTitle2") }}</h6>
            </b-nav-item>
            <b-nav-item to="/about">
              <p class="text-white mb-0">{{ $t("footerLink1") }}</p>
            </b-nav-item>
            <b-nav-item to="/terms">
              <p class="text-white mb-0">{{ $t("footerLink2") }}</p>
            </b-nav-item>
            <b-nav-item to="/privacy">
              <p class="text-white mb-0">{{ $t("footerLink3") }}</p>
            </b-nav-item>
          </b-nav>
        </b-col>
        <b-col>
          <b-nav vertical>
            <b-nav-item>
              <h6 class="text-white">{{ $t("footerTitle3") }}</h6>
            </b-nav-item>
            <b-nav-item href="mailto:info@netai.my">
              <p class="text-white">
                <!-- eslint-disable-next-line -->
                <b-icon icon="envelope-fill" class="mr-2" />info@netai.my
              </p>
            </b-nav-item>
          </b-nav>
          <b-nav vertical>
            <b-nav-item>
              <h6 class="text-white">{{ $t("footerTitle4") }}</h6>
            </b-nav-item>
          </b-nav>
          <b-nav>
            <b-nav-item href="http://www.facebook.com/netai" target="_blank">
              <!-- eslint-disable-next-line -->
              <b-icon icon="facebook" class="ml-1" variant="light" font-scale="1" />
            </b-nav-item>
            <b-nav-item href="http://www.twitter.com/netai" target="_blank">
              <!-- eslint-disable-next-line -->
              <b-icon icon="twitter" class="ml-1" variant="light" font-scale="1" />
            </b-nav-item>
            <b-nav-item href="http://www.linkedin.com/netai" target="_blank">
              <!-- eslint-disable-next-line -->
              <b-icon icon="linkedin" class="ml-1" variant="light" font-scale="1" />
            </b-nav-item>
          </b-nav>
        </b-col>
      </b-row>
    </b-jumbotron>
  </b-container>
</template>

<script>
// @ is an alias to /src

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  methods: {},
  mounted() {
    this.$store.state.isPageLoading = false;
  },
  beforeCreate() {
    this.$store.state.isPageLoading = true;
  },
};
</script>

<style scoped>
.home {
  background-color: white;
}
.jumbotron-1 {
  height: 50%;
  /* box-shadow: 0px 0px 5px 0px black; */
  text-align: center;
  background-image: url("../assets/city03.jpg");
  background-size: cover;
  background-position: bottom;
  backdrop-filter: 50%;
}
.jumbotron-2 {
  text-align: center;
  padding: 0px;
}
.box-avatar {
  padding-bottom: 30px;
}
</style>
