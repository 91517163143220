import Vue from "vue";
import "@/plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import axios from "axios";
import VueAxios from "vue-axios";
import Vuelidate from "vuelidate";
import VueScreen from "vue-screen";
import LightTimeline from "vue-light-timeline";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

import "@/assets/app.scss";

// axios.defaults.baseURL = "http://localhost:4003";
// axios.defaults.baseURL = "http://167.99.30.32:4003";
// axios.defaults.baseURL = "http://82.197.92.44:4003";
// axios.defaults.baseURL = "http://139.59.116.246:4003";
axios.defaults.baseURL = "https://api-revops.netai.my:4443";
// axios.defaults.baseURL =
//  "http://nodejs12181onubuntu2004-s-1vcpu-1gb-amd-sgp1-01:4003";

Vue.config.productionTip = false;
Vue.prototype.$eventBus = new Vue(); // Global event bus

Vue.use(VueAxios, axios);
Vue.use(Vuelidate);
Vue.use(VueScreen);
Vue.use(LightTimeline);

// Filters
Vue.filter("formatNumber", function (value: unknown) {
  return Number(value)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
});
Vue.filter("formatDateLocal", function (value: Date) {
  if (value) {
    const date = new Date(value);
    return date.toLocaleDateString("en-GB");
  }
});
Vue.filter("formatDate", function (value: Date) {
  if (value) {
    const date = new Date(value.toString().substring(0, 10));
    return date.toISOString();
  }
});
Vue.filter("formatUpperCase", function (value: string) {
  if (value) {
    return value.toUpperCase();
  }
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
