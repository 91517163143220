import Home from "../views/Home.vue";

const PublicMaster = () => import("/src/masters/PublicMaster.vue");

const Login = () => import("/src/views/Login.vue");
const About = () => import("/src/views/About.vue");
const Privacy = () => import("/src/views/Privacy.vue");
const Terms = () => import("/src/views/Terms.vue");

export default [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/",
    name: "PublicMaster",
    component: PublicMaster,
    children: [
      {
        path: "/home",
        name: "home",
        component: Home,
      },
      {
        path: "/login",
        name: "login",
        component: Login,
      },
      {
        path: "/about",
        name: "about",
        component: About,
      },
      {
        path: "/privacy",
        name: "privacy",
        component: Privacy,
      },
      {
        path: "/terms",
        name: "terms",
        component: Terms,
      },
    ],
  },
];
